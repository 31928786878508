import type { BoxProps } from "@project/ui";
import {
    isCloudinaryImage,
    Box,
    AspectRatio,
    HStack,
    CldImage,
    hasCloudinaryAsset,
    Heading,
    LinkBox,
    LinkOverlay,
    NextLink,
    Text,
} from "@project/ui";
import { getHrefForPageType } from "@/common/helpers/slug";
import type { PageTeamFieldsFragment } from "@/components/contentful/page-team/__generated/page-team.contentful.generated";
import { useMicrocopy } from "@/lib/contentful/microcopy/microcopy-context";

type TeamPageCardProps = BoxProps & {
    item: PageTeamFieldsFragment;
};

export const TeamPageCard = ({ item, ...boxProps }: TeamPageCardProps) => {
    const { get: getMicrocopy } = useMicrocopy();

    return (
        <LinkBox {...boxProps} data-group>
            <Box as="figure" mb={6} rounded="large" overflow="hidden">
                <AspectRatio ratio="3:4" bgColor="grey100">
                    {hasCloudinaryAsset(item.team?.asset) &&
                        isCloudinaryImage(item.team?.asset) && (
                            <CldImage
                                cloudinaryAsset={item.team!.asset}
                                sizes={"30vw"}
                                _groupHover={{ transform: "scale(1.02)" }}
                                transition="transform 0.30s ease"
                                wrapperProps={{
                                    isolation: "isolate", // Fixes Safari bug with rounded corners and scale transition
                                }}
                                crop={{
                                    type: "thumb",
                                    aspectRatio: "3:4",
                                }}
                            />
                        )}
                </AspectRatio>
            </Box>
            {item.team?.races && (
                <HStack align="baseline" color="grey300" spacing={1} mb={2}>
                    <Text size="caption">{getMicrocopy("pageTeamPage", "label.team")}</Text>
                </HStack>
            )}
            <Heading as="h3" size="headingMedium" fontWeight="400">
                <LinkOverlay as={NextLink} href={getHrefForPageType(item)}>
                    {item.team?.teamName}
                </LinkOverlay>
            </Heading>
        </LinkBox>
    );
};
