import { DateFormatter } from "@internationalized/date";
import { useRouter } from "next/router";
import { useMemo } from "react";

const COMMON_DATE_TIME_FORMATTER_OPTIONS = {
    Date: {
        year: "numeric",
        month: "long",
        day: "numeric",
    },
    DateTime: {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
    },
    Time: {
        hour: "numeric",
        minute: "numeric",
    },
} as const;

const useDateFormatter = (
    date: Date | string,
    options?: Intl.DateTimeFormatOptions,
    locale?: string,
    timeZoneLabel?: string | null
): string => {
    const { locale: nextLocale } = useRouter();

    const { dateObj } = useMemo(() => {
        if (typeof date === "string") {
            return {
                dateObj: new Date(date),
            };
        }
        return { dateObj: date };
    }, [date]);

    const formattedAndTranslatedDate = useMemo(
        () => new DateFormatter(locale ?? nextLocale ?? "en", options).format(dateObj),
        [dateObj, locale, nextLocale, options]
    );

    return [formattedAndTranslatedDate, timeZoneLabel].filter(Boolean).join(" ");
};

export { useDateFormatter, COMMON_DATE_TIME_FORMATTER_OPTIONS };
